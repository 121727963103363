import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import MainFooter from '../Components/Footer/MainFooter'
import CareerForm from '../Components/Pages/CareerForm'
import styles from './Careers.module.css'
import 'react-toastify/dist/ReactToastify.css';


const Careers = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    

    return (
        <div>
            <ToastContainer />
            <div className={styles.firstWrapper} >
                <div className={styles.heroContent}>
                </div>
                <div className={styles.overlay}>
                    <div className={styles.heroMessage}>
                        <div className={styles.mainTitle}>
                            <h1>Let's create experience that matters!</h1>
                            <p>Bring your talent to Qnnox & help us create the future of loyalty.</p>
                            <button className={styles.applyNow}>Apply Now</button>
                        </div>
                    </div>

                </div>
            </div>


            <div className={styles.openingsWrapper}>
                <div className={styles.openingsTitle}>
                    <h2>Look for the role that suits you!</h2>
                </div>
                <div>
                    
                   <CareerForm />

                </div>
            </div>

            <MainFooter />

        </div>
    )
}

export default Careers