import React from 'react'
import { FaDiamond } from 'react-icons/fa6'
import styles from './WhyItIsDifferent.module.css'
import { IoDiamondSharp } from "react-icons/io5";
import { GiClick } from "react-icons/gi";
import { FaCalculator } from "react-icons/fa";
import { BsCupHot } from "react-icons/bs";



const WhyItIsDifferentGeneral = (props) => {
    const { title, data } = props
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.eachColWrapper}>
                    <div className={styles.sectionWrapper}>
                        <div className={styles.sectionTitle}>
                            <h3>{title}</h3>
                        </div>
                    </div>
                    <div className={styles.cardsContainer}>
                        {data?.slice(0, (data.length / 2))?.map((item, index) => (
                            <div className={styles.eachCardWrapper}>
                                <div className={styles.cardTitle}>
                                    <div className={styles.titleIcon}>
                                        <GiClick size={30} color="white" />
                                    </div>
                                    <div className={styles.titleTxt}>
                                        <h4>
                                            {item?.title}
                                        </h4>
                                    </div>
                                </div>
                                <p>
                                    {item?.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.eachColWrapper}>
                    <div className={`${styles.cardsContainer} ${styles.leftAlign}`}>
                        {data?.slice((data.length / 2), data?.length)?.map((item, index) => (
                            <div className={styles.eachCardWrapper}>
                                <div className={styles.cardTitle}>
                                    <div className={styles.titleIcon}>
                                        <GiClick size={30} color="white" />
                                    </div>
                                    <div className={styles.titleTxt}>
                                        <h4>
                                            {item?.title}
                                        </h4>
                                    </div>
                                </div>
                                <p>
                                    {item?.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhyItIsDifferentGeneral