import React from 'react'
import styles from './BenefitEmployeeLoyalty.module.css'

import { IoIosChatbubbles } from "react-icons/io";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { GrCertificate, GrDocumentPerformance } from "react-icons/gr";

const BenefitEmployeeLoyalty = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.eachContent}>
                        <div className={styles.iconWrapper}>
                            <div className={styles.iconContainer}>
                                <GrCertificate size={36} color="white" />
                            </div>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.cwTitle}>Certifications</div>
                            <div className={styles.cwContent}>
                                Motivating employees to complete training and certification can be extremely beneficial. Now, you can track employee classes and recognise their accomplishments.
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachContent}>
                        <div className={styles.iconWrapper}>
                            <div className={styles.iconContainer}>
                                <IoIosChatbubbles size={36} color="white" />
                            </div>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.cwTitle}>Referrals</div>
                            <div className={styles.cwContent}>
                                Properly measuring staff performance and production provides accountability, allowing your organisation to reward top performers and provide additional training for others.
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachContent}>
                        <div className={styles.iconWrapper}>
                            <div className={styles.iconContainer}>
                                <GrDocumentPerformance size={36} color="white" />
                            </div>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.cwTitle}>Performance</div>
                            <div className={styles.cwContent}>
                                Your employees are a wonderful resource for new customer referrals (via their friends, family, and social media), as well as a great beginning point when recruiting new hires.
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachContent}>
                        <div className={styles.iconWrapper}>
                            <div className={styles.iconContainer}>
                                <BsFillQuestionCircleFill size={36} color="white" />
                            </div>
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.cwTitle}>Suggestions</div>
                            <div className={styles.cwContent}>
                                As your frontline, your employees will undoubtedly be able to provide some extremely valuable insights, recommended improvements, and client feedback. Need an employee survey? Please ask us.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BenefitEmployeeLoyalty;
