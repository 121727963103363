import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import MainFooter from '../Components/Footer/MainFooter';
import styles from './BookDemo.module.css';

const BookDemo = () => {

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#FFFFFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#FFFFFF";
            document.head.appendChild(meta);
        }
        window.scroll(0, 0)
    }, []);


    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        message: ''
    }

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        toast.success('Your demo has request, our team will call you soon!')
        console.log(formData);
        setFormData(initialFormData)
    };

    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.formContainer}>
                <h2 className={styles.title}>Book a Demo</h2>
                <form onSubmit={handleSubmit} className={styles.demoForm}>
                    <div className={styles.inputGroup}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="tel"
                            name="phone"
                            id="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label htmlFor="message">Message</label>
                        <textarea
                            name="message"
                            id="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className={styles.submitButton}>Submit</button>
                </form>
            </div>
            <MainFooter />
        </div>
    );
};

export default BookDemo;
