import React, { useEffect } from 'react'
import { FaCartArrowDown } from 'react-icons/fa6'
import { IoBagCheck } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import LBTGeneral from '../Components/Pages/LBTGeneral';

import { FaCartPlus } from "react-icons/fa6";



import WhoIsItFor from '../Components/Pages/WhoIsItFor'
import styles from './EarnAndBurn.module.css'
import MainFooter from '../Components/Footer/MainFooter';
import WhyItIsDifferent from '../Components/Pages/WhyItIsDifferent';
import { Link } from 'react-router-dom';

const EarnAndBurn = () => {
    const WhoIsItForData = [
        {
            icon: <FaCartArrowDown size={50} color="#8A30E2" />,
            content: 'Companies with high purchase frequency and fast purchase decisions.'
        },
        {
            icon: <IoBagCheck size={50} color="#8A30E2" />,
            content: 'Brands and retailers selling commodities.'
        },
        {
            icon: <IoWallet size={50} color="#8A30E2" />,
            content: 'Brands competing on value for money.'
        }
    ]

    const logicBehindThisData = [
        {
            icon: <FaCartPlus size={40} />,
            content: 'Incentivize purchases'
        },
        {
            icon: <IoBagCheck size={40} />,
            content: 'Give points'
        },
        {
            icon: <IoWallet size={40} />,
            content: 'Offer a reward'
        }
    ]
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    

    return (
        <div>
            <div className={styles.heroContainer}>
                <div>
                    <h1>
                        <span className={styles.boldTitle}>Increase Repeat Business</span> with High-Impact B2B Loyalty Programs.
                    </h1>
                    <p>
                        Enhance customer lifetime value with our Earn & Burn Loyalty and Rewards Program.
                    </p>
                    <p>
                        Keep your loyal customers engaged and coming back with personalized, real-time rewards that foster deeper connections and long-term loyalty.
                    </p>
                    <div className={styles.bookDemoWrapper}>
                        <Link to={`/book-demo`} className={styles.bookADemoBtn}>
                            Book A Demo
                        </Link>
                    </div>
                </div>
            </div>

            <LBTGeneral data={logicBehindThisData} title={`Earn & Burn Loyalty Programs`} />
            <WhoIsItFor data={WhoIsItForData} />

            <WhyItIsDifferent />



            <MainFooter />
        </div>
    )
}

export default EarnAndBurn