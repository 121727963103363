import React, { useEffect, useRef } from 'react';
import styles from './InfluencerLoyaltyText.module.css';

const InfluencerLoyaltyText = () => {
    const contentRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.visible);
                } else {
                    entry.target.classList.remove(styles.visible);
                }
            },
            { threshold: 0.1 }
        );

        if (contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                observer.unobserve(contentRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.centerText} ref={contentRef}>
            <p className={styles.heading}>Customers Are the Best Promoters of Your Products.</p>
            <p>
                People are more likely to purchase a product when it is recommended by close friends and family or an authentic influencer.
            </p>
            <div className={styles.section}>
                <h3>Create Relevant Relationships</h3>
                <p>
                    Reward customers for inviting like-minded shoppers to make a purchase in your store. Show how much you value their contribution and let them know they are a key part of your success.
                </p>
            </div>
            <div className={styles.section}>
                <h3>Easily Track Influencer Activity</h3>
                <p>
                    Each influencer receives a unique link, seamlessly integrated for sharing. These links provide companies with insight into who they should reward.
                </p>
            </div>
            <div className={styles.section}>
                <h3>Build Credibility</h3>
                <p>
                    Credible influencers usually have a large, engaged audience that brands can tap into to build their reputation and drive sales.
                </p>
            </div>
            <div className={styles.section}>
                <h3>Expand Your Network</h3>
                <p>
                    When working with new influencers, you might connect with new audiences. Create more meaningful and niche marketing initiatives, and build customer relationships.
                </p>
            </div>
        </div>
    );
};

export default InfluencerLoyaltyText;
