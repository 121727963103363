import React from 'react'

import { Routes, Route } from "react-router-dom";
import MainHeader from '../Components/Header/MainHeader';
import AboutUs from '../Pages/AboutUs';
import BookDemo from '../Pages/BookDemo';
import CallCenterServices from '../Pages/CallCenterServices';
import Careers from '../Pages/Careers';
import ChannelLoyalty from '../Pages/ChannelLoyalty';
import CorporateGifting from '../Pages/CorporateGifting';
import CustomerSupport from '../Pages/CustomerSupport';
import DataAnalyticsInLoyalty from '../Pages/DataAnalyticsInLoyalty';
import EarnAndBurn from '../Pages/EarnAndBurn';
import EmployeeLoyalty from '../Pages/EmployeeLoyalty';
import Gamification from '../Pages/Gamification';
import Home from '../Pages/Home';
import InfluencerLoyalty from '../Pages/InfluencerLoyalty';
import LoyaltyRewardsFulfillment from '../Pages/LoyaltyRewardsFulfillment';
import Privacy from '../Pages/Privacy';
import TermsOfUse from '../Pages/TermsOfUse';


const Navigator = () => {

    return (
        <div>
            <MainHeader />
            <Routes>

                <Route path="*" element={<Home />}></Route>
                <Route path="/" element={<Home />}></Route>
                <Route path="/home" element={<Home />}></Route>
                <Route path="/privacy" element={<Privacy />}></Route>
                <Route path="/terms-of-use" element={<TermsOfUse />}></Route>
                <Route path="/about-us" element={<AboutUs />}></Route>
                <Route path="/employee-loyalty" element={<EmployeeLoyalty />}></Route>
                <Route path="/loyalty-rewards-fulfillment" element={<LoyaltyRewardsFulfillment />}></Route>
                <Route path="/earn-and-burn" element={<EarnAndBurn />}></Route>
                <Route path="/influencer-loyalty" element={<InfluencerLoyalty />}></Route>
                <Route path="/gamification" element={<Gamification />}></Route>
                <Route path="/careers" element={<Careers />}></Route>
                <Route path="/channel-loyalty" element={<ChannelLoyalty />}></Route>
                <Route path="/call-center-services" element={<CallCenterServices />}></Route>
                <Route path="/data-analytics" element={<DataAnalyticsInLoyalty />}></Route>
                <Route path="/customer-support" element={<CustomerSupport />}></Route>
                <Route path="/corporate-gifting" element={<CorporateGifting />}></Route>
                <Route path="/book-demo" element={<BookDemo />}></Route>
            </Routes>
        </div>
    )
}

export default Navigator