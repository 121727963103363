import React from 'react';
import styles from './TrustedBy.module.css';

const TrustedBy = () => {
    const clients = [
        { name: 'Tata Power', logoUrl: 'https://companieslogo.com/img/orig/TATAPOWER.NS-aafa9ed2.png' },
        { name: 'Hexaware', logoUrl: 'https://www.logo.wine/a/logo/Hexaware_Technologies/Hexaware_Technologies-Logo.wine.svg' },
        { name: 'Microsoft', logoUrl: 'https://cdn.pixabay.com/photo/2013/02/12/09/07/microsoft-80658_1280.png' },
        { name: 'Google', logoUrl: 'https://www.freepnglogos.com/uploads/google-logo-png/file-google-logo-svg-wikimedia-commons-23.png' },
        { name: 'Amazon', logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
        { name: 'Zoom Info', logoUrl: 'https://assets.partnerfleet.app/gjmlk8gsjqa4xtktwtn4646mw0wt' },
        { name: 'Natural Remedies', logoUrl: 'https://www.indianveterinaryassociation.in/wp-content/uploads/2021/05/natural-remedies-logo.png' },
        { name: 'E.I.D. Parry', logoUrl: 'https://assets-netstorage.groww.in/stock-assets/logos2/EIDParry_79775166468_148.png' },
        { name: 'Samsung', logoUrl: 'https://www.hatchwise.com/wp-content/uploads/2022/10/PngItem_24638.png' },
        { name: 'Zomato', logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Zomato_Logo.svg/1200px-Zomato_Logo.svg.png' },
        { name: 'Swiggy', logoUrl: 'https://cdn.worldvectorlogo.com/logos/swiggy-logo.svg' },
    ];

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.title}>
                <h3>Trusted By Top Brands</h3>
            </div>
            <div className={styles.subTitle}>
                <p>We are trusted by those who are trusted by the world.</p>
            </div>
            <div className={styles.clientsWrapper}>
                <div className={styles.clients}>
                    {clients.map((item, index) => (
                        <div className={styles.eachBrand} key={index} title={item.name}>
                            <img src={item.logoUrl} alt={item.name} />
                        </div>
                    ))}
                    {/* Duplicating the logos for infinite loop */}
                    {clients.map((item, index) => (
                        <div className={styles.eachBrand} key={index + clients.length} title={item.name}>
                            <img src={item.logoUrl} alt={item.name} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TrustedBy;
