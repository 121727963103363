import React, { useEffect } from 'react'
import { FaAward, FaGift, FaPeoplePulling } from 'react-icons/fa6'
import { TbHandClick } from 'react-icons/tb'
import { MdShare } from "react-icons/md";
import { CgCommunity } from "react-icons/cg";
import { BsBagHeartFill } from "react-icons/bs";



import MainFooter from '../Components/Footer/MainFooter'
import LBTGeneral from '../Components/Pages/LBTGeneral'
import WhoIsItFor from '../Components/Pages/WhoIsItFor'
import styles from './Gamification.module.css'
import WhyItIsDifferentGeneral from '../Components/Pages/WhyItIsDifferentGeneral';
import { GiClick } from 'react-icons/gi';
const Gamification = () => {
    const WhoIsItForData = [
        {
            icon: <BsBagHeartFill size={50} color="#8A30E2" />,
            content: 'Business wanting to give a meaningful loyalty experience.'
        },
        {
            icon: <CgCommunity size={50} color="#8A30E2" />,
            content: 'Brands and retailers positioned on community building.'
        },
        {
            icon: <MdShare size={50} color="#8A30E2" />,
            content: 'Companies that share the same values with their customers.'
        }
    ]
    const logicBehindThisData = [
        {
            icon: <TbHandClick size={44} color="#B62CB9" />,
            content: 'Incentivize purchases'
        },
        {
            icon : <FaAward size={44} color="#B62CB9" />,
            content: 'Assign Badges'
        },
        {
            icon: <FaGift size={44} color="#B62CB9" />,
            content: 'Award Benefits'
        },
        {
            icon: <FaPeoplePulling size={44} color="#B62CB9" />,
            content: 'Encourage Engagement'
        },
        {
            icon: <TbHandClick size={44}  />,
            content: 'Incentivize Bonus'
        }
    ]

    const gamificationData = [
        {
            icon : <GiClick size={30} color="white" />,
            title : `Enhance Loyalty Programs with Achievement Levels and Tiers`,
            content : `Create an enriched loyalty experience by introducing achievement levels and tiered memberships. Keep customers engaged with unlockable perks as they progress through different loyalty tiers, encouraging deeper brand commitment.`   
        },
        {
            icon : <GiClick size={30} color="white" />,
            title : `Launch Gamified Referral Campaigns with Milestones and Rewards`,
            content : `Drive referrals by adding fun, milestone-based achievements to your campaigns. Motivate customers to spread the word by rewarding them as they hit specific referral targets, turning brand advocacy into a rewarding game.`   
        },
        {
            icon : <GiClick size={30} color="white" />,
            title : `Set Up Challenges Linked to Purchase History`,
            content : `Encourage more frequent purchases by setting up challenges that are tailored to customers' transaction history. Track their buying habits and reward them for consistent engagement with your brand.`   
        },
        {
            icon : <GiClick size={30} color="white" />,
            title : `Recognize and Reward Consistent Behavior`,
            content : `Strengthen customer loyalty by recognizing consistent actions over time. Reinforce positive behaviors by rewarding repeat purchases or engagements, encouraging a long-term relationship with your brand.`   
        },
        {
            icon : <GiClick size={30} color="white" />,
            title : `Define Personalized Achievements Based on Customer Behavior`,
            content : `Craft unique achievement milestones that reflect customer habits, like frequent visits or purchases. Recognize and reward these personalized behaviors to keep customers motivated and engaged.`   
        },
        {
            icon : <GiClick size={30} color="white" />,
            title : `Reward Consecutive Actions to Boost Engagement`,
            content : `Foster regular interaction by rewarding customers for completing actions in sequence, like visiting several times in a row. This approach keeps your audience actively involved with your brand.`   
        },
        {
            icon : <GiClick size={30} color="white" />,
            title : `Celebrate Customer Milestones with Special Rewards`,
            content : `Acknowledge your customers' journey by celebrating important milestones. Whether it’s their 10th purchase or 1-year anniversary with your brand, reward them for reaching meaningful achievements.`   
        },
        {
            icon : <GiClick size={30} color="white" />,
            title : `Introduce Gamified Tiers with Customizable Progression Rules`,
            content : `Elevate your loyalty program with gamified tiers that adapt to customer behavior. Set custom rules for upgrades and downgrades to create a dynamic, rewarding experience that keeps customers striving for the next level.`   
        }
    ]

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    

    return (
        <div>
            <div className={styles.header}>
            <h1>Gamification in Loyalty Programs</h1>
            </div>
            <LBTGeneral title="Gamified Loyalty Programs" data={logicBehindThisData} />
            <WhoIsItFor data={WhoIsItForData} />
            <WhyItIsDifferentGeneral title="How Gamification Benefits Your Loyalty Programs?" data={gamificationData} />
            <MainFooter />
        </div>
    )
}

export default Gamification