import React from 'react'
import styles from './WhoIsItFor.module.css'

const WhoIsItFor = (props) => {
    const { data } = props

    


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    <h2>
                        Who Is It For?
                    </h2>
                </div>
                <div>

                    <div className={styles.uspWrapper}>
                        {data?.map((item, index) => (
                            <div key={index} className={styles.eachUspWrapper}>
                                <div>
                                    {item?.icon}
                                </div>
                                <div>
                                    {item?.content}
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </div>
        </div>
    )
}

export default WhoIsItFor