import logo from './logo.svg';
import './App.css';
import Navigator from './MainNavigator/Navigator';

function App() {
  return (
    <Navigator />
  );
}

export default App;
