import React, { useState, useRef, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import styles from './CareerForm.module.css';

const CareerForm = () => {
    const formRef = useRef(null);


    // Initial state for form fields
    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        position: '',
        role: '',
        experience: '',
        location: '',
        message: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.classList.add(styles.animateIn);
        }
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleDropdownChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    // Function to reset the form
    const resetForm = () => {
        setFormData(initialFormData); // Reset formData to initial values
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
        resetForm()
        toast('Application submitted successfully!')
    };

    return (
        <div className={styles.formWrapper} ref={formRef}>
            <h2 className={styles.formTitle}>Join Our Team</h2>
            <form className={styles.careerForm} onSubmit={handleSubmit}>
                <div className={styles.fieldsWrapper}>
                    <div className={styles.eachCol}>
                        <div className={styles.formGroup}>
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="phone">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                placeholder="Your Phone Number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="experience">Experience Level</label>
                            <select
                                id="experience"
                                value={formData.experience}
                                onChange={handleDropdownChange}
                                required
                            >
                                <option value="">Select level</option>
                                <option value="Fresher">Fresher</option>
                                <option value="Experience">Experience</option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.eachCol}>
                        <div className={styles.formGroup}>
                            <label htmlFor="role">Role</label>
                            <select
                                id="role"
                                value={formData.role}
                                onChange={handleDropdownChange}
                                required
                            >
                                <option value="">Select a role</option>
                                <option value="Management Trainee">Management Trainee</option>
                                <option value="Sales Executive">Sales Executive</option>
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="location">Location</label>
                            <select
                                id="location"
                                value={formData.location}
                                onChange={handleDropdownChange}
                                required
                            >
                                <option value="">Select a location</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Noida">Noida</option>
                                <option value="Gurugram">Gurugram</option>
                                <option value="Bengaluru">Bengaluru</option>
                                <option value="Chennai">Chennai</option>
                            </select>
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                placeholder="Tell us about yourself"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </div>

                    </div>
                </div>
                <div className={styles.submitBtnWrapper}>
                    <button type="submit" className={styles.submitButton}>Submit</button>
                </div>
            </form>
        </div>
    );
};

export default CareerForm;
