import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MainFooter from '../Components/Footer/MainFooter'
import MainHeader from '../Components/Header/MainHeader'
import SecondPaint from '../Components/Home/SecondPaint'
import TrustedBy from '../Components/Home/TrustedBy'
import VideoSection from '../Components/Home/VideoSection'
import styles from './Home.module.css'

const Home = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    
    return (
        <div className={styles.mainWrapper}>
            {/* <MainHeader /> */}
            <div className={styles.firstPaintWrapper}>
                <div className={styles.logoWrapper}>
                    <div className={styles.logoIcon}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/logo-white.png" />
                    </div>
                </div>
                <div className={styles.brandName}>
                    <h2>Qnnox</h2>
                </div>
                <div className={styles.theAllInOne}>
                    <h4>
                        The All-in-One Platform for Customer
                    </h4>
                    <h4>
                        Engagement, Promotions, and Loyalty
                    </h4>
                </div>
                <div className={styles.incRevLine}>
                    Increase revenue, efficiency, and customer satisfaction with Qnnox.
                </div>
            </div>

            <SecondPaint />

            <VideoSection />
           
           <TrustedBy />

            <div className={styles.featureWrapper}>
                <div className={styles.featureContainer}>
                    <div className={styles.fcHeader}>
                        <div className={styles.fcTitle}>
                            Unify Your Customer Journey with Qnnox's Powerful Features
                        </div>
                        <div className={styles.fcSubtitle}>
                            Drive engagement, personalize experiences, and gain insights with our all-in-one platform.
                        </div>
                    </div>
                    <div className={styles.fcBody}>
                        <div className={styles.allFeatures}>
                            <div className={styles.eachFeatureWrapper}>
                                <div className={styles.efwInner}>
                                    <h4>
                                        Loyalty Programs
                                    </h4>
                                </div>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <div className={styles.efwInner}>
                                    <h4>
                                        Personalized Promotions
                                    </h4>
                                </div>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <div className={styles.efwInner}>
                                    <h4>
                                        Marketing Automation
                                    </h4>
                                </div>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <div className={styles.efwInner}>
                                    <h4>
                                        Data Analytics
                                    </h4>
                                </div>
                            </div>

                            <div className={styles.eachFeatureWrapper}>
                                <div className={styles.efwInner}>
                                    <h4>
                                        Systems Integrations
                                    </h4>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>


            </div>

            <MainFooter />

        </div >
    )
}

export default Home