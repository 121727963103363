import React from 'react';
import styles from './HowChannelLoyaltyWorks.module.css';

const steps = [
    "Identify and classify trade partners based on key parameters.",
    "Enroll partners and share the benefits of the loyalty program.",
    "Develop strategies to reward partners for hitting specific targets.",
    "Maintain consistent communication to support your partners.",
    "Track performance and keep partners updated on their progress.",
    "Evaluate results and refine the program as needed for continuous improvement."
];

const HowChannelLoyaltyWorks = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>How Does the Channel Loyalty Program Work?</h2>
            <p className={styles.subtitle}>
                Our Channel Partner Loyalty Program is crafted to help businesses reach their goals and increase profits by building stronger relationships with trade partners.
            </p>
            <div className={styles.steps}>
                {steps.map((step, index) => (
                    <div key={index} className={styles.step}>
                        <div className={styles.stepNumber}>{index + 1}</div>
                        <div className={styles.stepText}>{step}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HowChannelLoyaltyWorks;
