import React from 'react'
import styles from './LBTGeneral.module.css'
import { TbHandClick } from "react-icons/tb";
import { FaAward, FaPeoplePulling } from "react-icons/fa6";
import { FaGift } from "react-icons/fa";

const LBTGeneral = (props) => {
    const { title, data } = props
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>The Logic Behind <br /> {title}</h2>
            <div className={styles.steps}>
                {data?.map((item, index) => (
                    <>
                        <div className={styles.step}>
                            <div className={`${styles.icon} ${styles.incentivizeIcon}`}>
                                {item?.icon}
                            </div>
                            <p className={styles.label}>{item?.content}</p>
                        </div>
                        {index+1 !== data?.length &&  <div className={index%2 ? styles.halfArcLower : styles.halfArc}></div>}
                    </>
                ))}
            </div>
        </div>
    )
}

export default LBTGeneral