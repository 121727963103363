import React, { useEffect } from 'react';
import MainFooter from '../Components/Footer/MainFooter';
import styles from './DataAnalyticsInLoyalty.module.css';

const DataAnalyticsInLoyalty = () => {
    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#FFFFFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#FFFFFF";
            document.head.appendChild(meta);
        }
        window.scroll(0, 0)
    }, []);

    return (
        <div className={styles.mainWrapper}>

                <h1 className={styles.title}>Data Analytics</h1>

            <div className={styles.container}>

                <section className={styles.section}>
                    <h2 className={styles.subtitle}>Gain an Advantage with Real-Time Reporting</h2>
                    <p className={styles.text}>
                        Forget about quarterly reports – check and analyze your data at any moment thanks to a wide range of reports and dashboards updated in real time. Use that knowledge to improve your marketing performance.
                    </p>
                    <p className={styles.text}>
                        Utilize our business-ready reports or create new ones from scratch to gain a more in-depth look at your marketing processes.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.subtitle}>Use Data to Further Personalize Your Marketing</h2>
                    <p className={styles.text}>
                        Stop improvising and base your marketing efforts on facts, stats, and figures to engage the right person with the right message.
                    </p>
                    <p className={styles.text}>
                        Discover the best times to share your content and which marketing channels are most effective. Our data analytics platform helps you create personalized customer experiences, building long-lasting relationships by showing clients you understand their needs.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.subtitle}>Get a 360° View of All Your Customers</h2>
                    <p className={styles.text}>
                        Leverage the power of AI to understand your customers deeply – their shopping habits, preferred products, spending capacity, and brand loyalty trends.
                    </p>
                    <p className={styles.text}>
                        Identify patterns in customer data to predict future purchases or detect potential customer churn, and learn how to prevent it.
                    </p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.subtitle}>Have AI Detect & Prevent Frauds for You</h2>
                    <p className={styles.text}>
                        Let our data analytics platform monitor your customer data to identify and prevent fraud before it impacts your business.
                    </p>
                    <p className={styles.text}>
                        Be proactive and reduce false positives that require time-consuming manual reviews. Enhance the security of your loyalty program to protect valuable customer relationships.
                    </p>
                </section>

            </div>
            <MainFooter />
        </div>
    );
};

export default DataAnalyticsInLoyalty;
