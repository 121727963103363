import React, { useEffect, useRef, useState } from 'react';
import Lady from './Lady';
import PurpleBg from './PurpleBg';
import styles from './SecondPaint.module.css';

const SecondPaint = () => {
    const [isVisible, setIsVisible] = useState(false);
    const mainContentRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 } // Trigger when 10% of the element is in view
        );

        if (mainContentRef.current) {
            observer.observe(mainContentRef.current);
        }

        return () => {
            if (mainContentRef.current) {
                observer.unobserve(mainContentRef.current);
            }
        };
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div
                    ref={mainContentRef}
                    className={`${styles.mainContent} ${isVisible ? styles.animateContent : ''}`}
                >
                    <div className={`${styles.imgWrapper} ${isVisible ? styles.animateImg : ''}`}>
                        <div className={styles.imgContainer}>
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/lady-cheerful.png" alt="Cheerful Lady" />
                        </div>
                    </div>
                    <div className={styles.contentWrapper}>
                        <div className={styles.contentHolder}>
                            <div className={styles.chUpper}>
                                <h2>Revitalizing Customer Loyalty</h2>
                            </div>
                            <div className={styles.chLower}>
                                <p>How to enhance loyalty programs and improve conversion</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecondPaint;
