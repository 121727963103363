import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaCross } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import styles from './MainHeader.module.css';
import { MdOutlineMenu } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";



const MainHeader = () => {
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const location = useLocation()

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ffffff");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ffffff";
            document.head.appendChild(meta);
        }

        // Cleanup: Re-enable scroll if component unmounts
        return () => {
            document.body.style.overflowY = "auto";
        };
    }, []);


    const toggleSlider = () => {
        setIsSliderOpen((prev) => {
            const newIsSliderOpen = !prev;
            document.body.style.overflowY = newIsSliderOpen ? "hidden" : "auto"; // Targeting the body tag
            return newIsSliderOpen;
        });
    };
    // Close slider on route change
    useEffect(() => {
        setIsSliderOpen(false);
        document.body.style.overflowY = "auto"; // Ensure scrolling is enabled
    }, [location]);



    return (
        <>
            <div className={styles.homeHeader}>
                <div className={styles.homeHeaderContainer}>
                    <Link to={`/`} className={styles.hhcLeft}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/logo-black.svg" alt="Logo" />
                    </Link>

                    {/* Main navigation section for desktop */}
                    <div className={`${styles.hhcCentre} ${isSliderOpen ? styles.hideOnMobile : ''}`}>
                        <div className={styles.hhcCentreContainer}>
                            <div className={styles.eachNavWrapper}>
                                <Link to={`/loyalty-programs`}>Our Programs&nbsp;<FaChevronDown /> </Link>
                                <div className={styles.subMenu}>
                                    <Link to={`/channel-loyalty`}>Channel Loyalty</Link>
                                    <Link to={`/employee-loyalty`}>Employee Loyalty</Link>
                                    <Link to={`/influencer-loyalty`}>Influencer Loyalty</Link>
                                    <Link to={`/earn-and-burn`}>Earn & Burn Programs</Link>
                                </div>
                            </div>
                            <div className={styles.eachNavWrapper}>
                                <Link to={`/corporate-gifting`}>Our Services&nbsp;<FaChevronDown /></Link>
                                <div className={styles.subMenu}>
                                    <Link to={`/call-center-services`}>Call Center Services</Link>
                                    <Link to={`/data-analytics`}>Data Analytics</Link>
                                    <Link to={`/loyalty-rewards-fulfillment`}>Rewards Fulfillment</Link>
                                    <Link to={`/corporate-gifting`}>Corporate Gifting</Link>
                                </div>
                            </div>
                            <div className={styles.eachNavWrapper}>
                                <Link to={`/about-us`}>About Us&nbsp;<FaChevronDown /></Link>
                                <div className={styles.subMenu}>
                                    <Link to={`/careers`}>Careers</Link>
                                    <Link to={`/customer-support`}>Our Offices</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Get in touch link and menu button for mobile */}
                    <div className={styles.hhcRight}>
                        <Link to={`/book-demo`}>Get In Touch</Link>
                        <MdOutlineMenu size={30} onClick={toggleSlider} className={styles.menuIcon} />
                    </div>
                </div>
            </div>

            {/* Slider for mobile view */}
            {isSliderOpen && (
                <div className={`${styles.mobileSlider} ${isSliderOpen ? styles.open : ''}`} onClick={toggleSlider}>
                    <div className={styles.sliderContent} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.closeBtn} onClick={() => setIsSliderOpen(false)} >
                            <RxCross1 size={28} />
                        </div>
                        <div className={styles.msNavWrapper}>
                            <div className={styles.msNavTitle}>
                                <Link to={`/loyalty-programs`}>Our Programs</Link>
                            </div>
                            <div className={styles.msSubMenu}>
                                <Link to={`/channel-loyalty`}>Channel Loyalty</Link>
                                <Link to={`/employee-loyalty`}>Employee Loyalty</Link>
                                <Link to={`/influencer-loyalty`}>Influencer Loyalty</Link>
                                <Link to={`/earn-and-burn`}>Earn & Burn Programs</Link>
                            </div>
                        </div>
                        <div className={styles.msNavWrapper}>
                            <div className={styles.msNavTitle}>
                                <Link to={`/corporate-gifting`}>Our Services</Link>
                            </div>
                            <div className={styles.msSubMenu}>
                                <Link to={`/call-center-services`}>Call Center Services</Link>
                                <Link to={`/data-analytics`}>Data Analytics</Link>
                                <Link to={`/loyalty-rewards-fulfillment`}>Rewards Fulfillment</Link>
                                <Link to={`/gamification`}>Gamification</Link>
                                <Link to={`/corporate-gifting`}>Corporate Gifting</Link>
                            </div>
                        </div>
                        <div className={styles.msNavWrapper}>
                            <div className={styles.msNavTitle}>
                                <Link to={`/about-us`}>About Us</Link>
                            </div>
                            <div className={styles.msSubMenu}>
                                <Link to={`/careers`}>Careers</Link>
                                <Link to={`/customer-support`}>Our Offices</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MainHeader;
