import React, { useEffect } from 'react'
import MainFooter from '../Components/Footer/MainFooter'
import InfluencerLoyaltyConnecting from '../Components/Pages/InfluencerLoyaltyConnecting'
import InfluencerLoyaltyHighlights from '../Components/Pages/InfluencerLoyaltyHighlights'
import InfluencerLoyaltyText from '../Components/Pages/InfluencerLoyaltyText'
import styles from './InfluencerLoyalty.module.css'

const InfluencerLoyalty = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    
    return (
        <div>
            <div className={styles.mainTitle}>
                <h1>Influencer Loyalty</h1>
                <h2>Leverages Word-of-mouth</h2>
            </div>
            <div className={styles.secondLine}>
                <h4>
                    Collaborate With Ideal Influencers Through Thoughtfully Crafted Loyalty Programs,
                    Drawing Their Followers to Your Brand.
                </h4>
                <p>
                    Expand your reach and engage customers across markets with our customized, value-focused influencer loyalty solutions.
                </p>
            </div>

            <InfluencerLoyaltyConnecting />

            <InfluencerLoyaltyText />

            <InfluencerLoyaltyHighlights />
            <MainFooter />
        </div>
    )
}

export default InfluencerLoyalty