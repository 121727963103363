import React, { useEffect } from 'react';
import MainFooter from '../Components/Footer/MainFooter';
import LogicBehindThis from '../Components/Pages/LogicBehindThis';
import BenefitEmployeeLoyalty from './BenefitEmployeeLoyalty';
import styles from './EmployeeLoyalty.module.css';

const EmployeeLoyalty = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    

    return (
        <div className={styles.mainWrapper}>
            <section className={styles.heroSection}>
                <div>
                <h1 className={styles.heroTitle}>Qnnox INCENTIVeX – Employee Rewards and Technology Solutions</h1>
                <p className={styles.heroSubtitle}>Keeping your Sales team motivated and achieving targets beyond expectations.</p>
                </div>
                <div className={styles.blobWrapper}>
                    <div className={styles.blobContainer}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/employee-loyalty.jpg" />
                    </div>
                </div>
            </section>
            <LogicBehindThis />
            <BenefitEmployeeLoyalty />

            <section className={styles.contentSection}>
                <h2 className={styles.sectionTitle}>Why Choose Qnnox INCENTIVeX?</h2>
                <p className={styles.sectionDescription}>
                    Performance of the Sales team determines the fortunes of any organization. They are the face of your organization. Sometimes competing products seem to be equal in terms of features, but it is the people who represent the product in the market who can make the difference. But how do you keep your Sales team motivated to perform beyond the call of duty? <strong>Qnnox INCENTIVeX is the solution.</strong>
                </p>

                <p className={styles.sectionDescription}>
                    At Qnnox, our experts have created a perfect plug-and-play method for keeping your sales professionals motivated. We help you understand their psyche and keep them driven to achieve their targets.
                </p>

                <h3 className={styles.subTitle}>Program Features</h3>
                <ul className={styles.featureList}>
                    <li>Structured incentive programs</li>
                    <li>Dedicated consultants who monitor the program to ensure success</li>
                    <li>Rewards that are truly worth performing for</li>
                    <li>Technology to help you budget, allocate, and disburse rewards</li>
                    <li>Attractive and user-friendly interface for rewards catalog</li>
                    <li>Door deliveries across the country</li>
                </ul>

                <h3 className={styles.subTitle}>How We Help You Achieve Success</h3>
                <p className={styles.sectionDescription}>
                    Qnnox INCENTIVeX is a template-driven program created with the help of veteran sales professionals and by adopting best practices in the sales domain. Goal setting, team building, and sales training are some of the core areas considered while creating the program, all in collaboration with your Sales managers.
                </p>

                <p className={styles.sectionDescription}>
                    Qnnox also helps you reduce your incentive costs while giving you quality reward options.
                </p>
            </section>
            
            <MainFooter />
        </div>
    );
};

export default EmployeeLoyalty;
