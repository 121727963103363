import React, { useRef, useEffect } from 'react';
import { BiSolidCoupon, BiSolidNotification, BiSolidOffer } from 'react-icons/bi';
import { BsFillGeoFill } from 'react-icons/bs';
import { FaDotCircle } from 'react-icons/fa';
import { FaAward, FaBrain, FaCoins } from 'react-icons/fa6';
import { GiArchiveRegister, GiCardJackSpades } from 'react-icons/gi';
import { GrMapLocation, GrMoney } from 'react-icons/gr';
import { IoMdAnalytics } from 'react-icons/io';
import { MdCampaign, MdCloudSync, MdDiscount, MdDocumentScanner, MdPublishedWithChanges, MdQrCodeScanner } from 'react-icons/md';
import { TbChartDonutFilled, TbFileUpload, TbShoppingCartUp } from 'react-icons/tb';
import styles from './LoyaltyProgramDashboard.module.css';

// Hook for detecting visibility on the screen
const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = React.useState(false);

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#FFE5FF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#FFE5FF";
            document.head.appendChild(meta);
        }
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting),
            { threshold: 0.2 }
        );

        if (ref.current) observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref]);

    return isIntersecting;
};

const ProfileCard = () => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <div ref={ref} className={`${styles.profileCard} ${isVisible ? styles.animateSlideInLeft : ''}`}>
            <div className={styles.ppWrapper}>
                <img src="https://www.bizcover.co.nz/wp-content/uploads/2023/01/iStock-469295486-scaled-1.jpg" alt="Profile" className={styles.profilePic} />
            </div>
            <div className={styles.pcDetails}>
                <h3>Mr. T Shekar</h3>
                <span>Contractor</span>
                <p>Total Balance: 50,00,000</p>
                <p>Redeemable Balance: 45,00,000</p>
                <p className={styles.goldMember}>Gold Member</p>
            </div>
        </div>
    );
};

const ActionsPanel = () => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <div ref={ref} className={`${styles.actionsPanel} ${isVisible ? styles.animateSlideInRight : ''}`}>
            <div className={styles.actionButton}><MdQrCodeScanner size={28} /> Scan Code</div>
            <div className={styles.actionButton}><MdDocumentScanner size={28} />Scan Invoice</div>
            <div className={styles.actionButton}><TbFileUpload size={28} />Upload Invoice</div>
            <div className={styles.actionButton}><GiArchiveRegister size={28} /> Register Sale</div>
            <div className={styles.actionButton}><MdCloudSync size={28} />Cloud Sync</div>
        </div>
    );
};

const CloudSync = () => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <div ref={ref} className={`${styles.cloudSync} ${isVisible ? styles.animateFadeIn : ''}`}>
            <div className={`${styles.erp} ${styles.csEachBall}`}>ERP</div>
            <div className={`${styles.crm} ${styles.csEachBall}`}>CRM</div>
            <div className={`${styles.pos} ${styles.csEachBall}`}>POS</div>
            <div className={`${styles.validation} ${styles.csEachBall}`}>Validation & Sync</div>
            <div className={styles.cloudIllustration}>
                <img src="https://cdn.dribbble.com/users/570218/screenshots/5593634/datacenters.gif" alt="Cloud" />
            </div>
        </div>
    );
};

const FeatureSection = ({ headerIcon, title, features }) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <div ref={ref} className={`${styles.featureSection} ${isVisible ? styles.animateFadeInUp : ''}`}>
            <div className={styles.fsTitleCard}>
                {headerIcon}
                <h4>{title}</h4>
            </div>
            <div className={styles.featureItems}>
                {features.map((feature, index) => (
                    <div key={index} className={styles.featureItem}>
                        <div>
                            {feature?.icon}
                        </div>
                        <div className={styles.fiTitle}>
                            {feature?.title}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const LoyaltyProgramDashboard = () => {
    return (
        <div className={styles.dashboard}>
            <ProfileCard />
            <ActionsPanel />
            <CloudSync />
            <FeatureSection
                headerIcon={<FaCoins size={34} color="#8D27B7" />}
                title="Points Engine"
                features={[
                    {
                        icon: <FaDotCircle color="#8D27B7" size={30} />,
                        title: 'Base Rule'
                    },
                    {
                        icon: <GrMoney color="#8D27B7" size={30} />,
                        title: 'Bonus Rule'
                    },
                    {
                        icon: <GiCardJackSpades color="#8D27B7" size={30} />,
                        title: 'Jackpot'
                    }
                ]}
            />
            <FeatureSection
                headerIcon={<TbChartDonutFilled size={34} color="#8D27B7" />}
                title="Segmentation"
                features={[
                    {
                        icon: <GrMapLocation color="#8D27B7" size={30} />,
                        title: 'Demography'
                    },
                    {
                        icon: <BsFillGeoFill color="#8D27B7" size={30} />,
                        title: 'Geography'
                    },
                    {
                        icon: <BiSolidCoupon color="#8D27B7" size={30} />,
                        title: 'Point Based'
                    }
                ]}
            />
            <FeatureSection
                headerIcon={<FaBrain size={34} color="#8D27B7" />}
                title="AI Engine"
                features={[
                    {
                        icon: <MdPublishedWithChanges color="#8D27B7" size={30} />,
                        title: 'Slab Change'
                    },
                    {
                        icon: <IoMdAnalytics color="#8D27B7" size={30} />,
                        title: 'Predictions'
                    },
                    {
                        icon: <TbShoppingCartUp color="#8D27B7" size={30} />,
                        title: 'Cart Alert'
                    }
                ]}
            />
            <FeatureSection
                headerIcon={<MdCampaign size={34} color="#8D27B7" />}
                title="Campaign"
                features={[
                    {
                        icon: <BiSolidNotification color="#8D27B7" size={30} />,
                        title: 'Notifications'
                    },
                    {
                        icon: <MdDiscount color="#8D27B7" size={30} />,
                        title: 'Promotions'
                    },
                    {
                        icon: <BiSolidOffer color="#8D27B7" size={30} />,
                        title: 'Offers'
                    }
                ]}
            />
        </div>
    );
};

export default LoyaltyProgramDashboard;
