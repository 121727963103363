import React from 'react'
import { FaDiamond } from 'react-icons/fa6'
import styles from './WhyItIsDifferent.module.css'
import { IoDiamondSharp } from "react-icons/io5";
import { GiClick } from "react-icons/gi";
import { FaCalculator } from "react-icons/fa";
import { BsCupHot } from "react-icons/bs";



const WhyItIsDifferent = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.eachColWrapper}>
                    <div className={styles.sectionWrapper}>
                        <div className={styles.sectionTitle}>
                            <h3>What Sets Earn&Burn Apart From Other Loyalty Programs?</h3>
                        </div>
                    </div>
                    <div className={styles.cardsContainer}>
                        <div className={styles.eachCardWrapper}>
                            <div className={styles.cardTitle}>
                                <div className={styles.titleIcon}>
                                    <GiClick size={30} color="white" />
                                </div>
                                <div className={styles.titleTxt}>
                                    <h4>
                                        Seamless Implementation and Management
                                    </h4>
                                </div>
                            </div>
                            <p>
                                Point-based programs reward customers based on the
                                value or volume of their purchases, treating all
                                customers equally without the need for segmentation.
                                This makes the setup process fast and straightforward,
                                as only purchases are rewarded.
                            </p>
                        </div>
                        <div className={styles.eachCardWrapper}>
                            <div className={styles.cardTitle}>
                                <div className={styles.titleIcon}>
                                    <FaCalculator size={30} color="white" />
                                </div>
                                <div className={styles.titleTxt}>
                                    <h4>
                                        Cost-Effective with Clear ROI
                                    </h4>
                                </div>
                            </div>
                            <p>
                                Earn&Burn programs provide a low-risk, high-return investment. With reward redemption limited to available loyalty points, these programs are straightforward to
                                implement and more affordable compared to traditional discount models.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.eachColWrapper}>
                    <div className={`${styles.cardsContainer} ${styles.leftAlign}`}>
                        <div className={styles.eachCardWrapper}>
                            <div className={styles.cardTitle}>
                                <div className={styles.titleIcon}>
                                    <IoDiamondSharp size={30} color="white" />
                                </div>
                                <div className={styles.titleTxt}>
                                    <h4>
                                        High Perceived Value
                                    </h4>
                                </div>
                            </div>
                            <p>
                                Customers see these programs as excellent deals,
                                with rewards offering a greater
                                perceived value than their actual cost.
                                Additionally, unredeemed points translate into extra savings for companies.
                            </p>
                        </div>
                        <div className={styles.eachCardWrapper}>
                            <div className={styles.cardTitle}>
                                <div className={styles.titleIcon}>
                                    <BsCupHot size={30} color="white" />
                                </div>
                                <div className={styles.titleTxt}>
                                    <h4>
                                        Straightforward and User-Friendly
                                    </h4>
                                </div>
                            </div>
                            <p>
                                Earn&Burn programs are easy for customers to grasp: collect loyalty points and redeem them. This simplicity enables customers to quickly understand the system, start earning, and redeem rewards with ease.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhyItIsDifferent