import React, { useEffect, useRef } from 'react';
import styles from './LRFUSPs.module.css';
import { FaListUl } from "react-icons/fa";
import { TbHandClick, TbTruckDelivery } from "react-icons/tb";
import { MdAutoMode, MdNotificationsActive } from "react-icons/md";
import { GiArtificialHive } from "react-icons/gi";
import { BiSelectMultiple } from "react-icons/bi";

const LRFUSPs = () => {
    const uspRefs = useRef([]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.visible);
                    }
                });
            },
            { threshold: 0.1 }
        );

        uspRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        return () => observer.disconnect();
    }, []);

    return (
        <div className={styles.uspWrapper}>
            <div className={styles.uspContainer}>
                {[ // Array of USP items for easy mapping
                    { id: 1, icon: <FaListUl color="white" size={24} />, title: 'Real-Time Product Catalog', content: 'Our Product Information Management (PIM) system ensures you see the most accurate, up-to-date information across all channels, from availability to pricing. We synchronize inventory in real-time, so you’re never left guessing.' },
                    { id: 2, icon: <TbHandClick color="white" size={24} />, title: 'Effortless Order Placement', content: 'With multichannel integration, you can place your order from any platform—website, mobile app, or other marketplaces. Once placed, your order syncs instantly, ensuring rapid processing.' },
                    { id: 3, icon: <MdAutoMode color="white" size={24} />, title: 'Automated Order Processing', content: 'Your order is instantly validated, allocated, and confirmed through our Order Management System (OMS). We handle everything from fraud checks to inventory allocation, allowing for smooth, hands-off order processing.' },
                    { id: 4, icon: <GiArtificialHive color="white" size={24} />, title: 'Intelligent Inventory and Fulfillment Assignment', content: 'Our AI-powered Inventory Management assigns your order to the most efficient fulfillment center, reducing travel time and cost. Predictive analytics prevent stockouts by managing inventory levels based on real-time demand.' },
                    { id: 5, icon: <BiSelectMultiple color="white" size={24} />, title: 'Optimized Shipping and Logistics Partner Selection', content: 'We use automated carrier selection and route optimization algorithms to assign the best logistics partner, ensuring the fastest and most economical shipping route for each order.' },
                    { id: 6, icon: <MdNotificationsActive color="white" size={24} />, title: 'Real-Time Tracking and Notifications', content: 'Our IoT-enabled GPS tracking provides you with real-time updates as your order moves through each stage. Notifications are sent directly to your app, SMS, or email to keep you informed, every step of the way.' },
                    { id: 7, icon: <TbTruckDelivery color="white" size={24} />, title: 'Pickup and Proof of Delivery', content: 'Digital confirmations are captured from pickup to delivery using RFID/barcode scanning and Electronic Proof of Delivery (ePOD). You receive confirmation once your order is delivered, with full visibility throughout the journey.' },
                ].map((usp, index) => (
                    <div 
                        key={usp.id} 
                        ref={(el) => (uspRefs.current[index] = el)} 
                        className={styles.eachUspStep}
                    >
                        <div className={styles.eupHeader}>
                            <div className={styles.eupIcon}>{usp.icon}</div>
                            <div className={styles.eupTitle}>
                                {/* <div>{usp.id}</div> */}
                                <div>{usp.title}</div>
                            </div>
                        </div>
                        <div className={styles.eupContent}>
                            <p>{usp.content}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LRFUSPs;
