import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MainFooter.module.css'

const MainFooter = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.logoWrapper}>

                </div>
                <div className={styles.bodyWrapper}>
                    <div className={styles.leftColWrapper}>
                        <div className={styles.eachColWrapper}>
                            <div className={styles.eachColTitle}>For Businesses</div>
                            <div className={styles.eachColNavWrapper}>
                                <div className={styles.eachNav}>
                                    <Link to="/corporate-gifting">Corporate Gifting</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/channel-loyalty">Channel Loyalty</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/employee-loyalty">Employee Loyalty</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/influencer-loyalty">Influencer Loyalty</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/earn-and-burn">Earn & Burn Programs</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/call-center-services">Call-Center Service</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/data-analytics">Data Analytics</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/gamification">Gamification</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/loyalty-rewards-fulfillment">Rewards Fulfillment</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/book-demo">Book Demo</Link>
                                </div>
                                {/* <div className={styles.eachNav}>
                                    <Link to="/">Tech Integrations</Link>
                                </div> */}
                                <div className={styles.eachNav}>
                                    <a href="https://ca.qnnox.com" target="_blank">Dashboard Login</a>
                                </div>
                                {/* <div className={styles.eachNav}>
                                    <Link to="/">Resources & Insights</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className={styles.eachColWrapper}>
                            <div className={styles.eachColTitle}>For App Users</div>
                            <div className={styles.eachColNavWrapper}>
                                <div className={styles.eachNav}>
                                    <Link to="/">Marketplace</Link>
                                </div>
                                {/* <div className={styles.eachNav}>
                                    <Link to="/">Find Offers</Link>
                                </div> */}
                                {/* <div className={styles.eachNav}>
                                    <Link to="/">Trouble with App</Link>
                                </div> */}
                                <div className={styles.eachNav}>
                                    <Link to="/customer-support">Customer Support</Link>
                                </div>
                            </div>
                        </div>
                        <div className={styles.eachColWrapper}>
                            <div className={styles.eachColTitle}>Partnerships</div>
                            <div className={styles.eachColNavWrapper}>
                                <div className={styles.eachNav}>
                                    <Link to="/">Affiliates</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/">Sell Products</Link>
                                </div>
                            </div>
                        </div>
                        <div className={styles.eachColWrapper}>
                            <div className={styles.eachColTitle}>Company</div>
                            <div className={styles.eachColNavWrapper}>
                                <div className={styles.eachNav}>
                                    <Link to="/about-us">About Us</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/careers">Careers</Link>
                                </div>
                                <div className={styles.eachNav}>
                                    <Link to="/">Blog</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightColWrapper}>
                        <div className={styles.getItOnStore}>
                            <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/get-it-on-store.png" alt="get it on store" />
                        </div>
                    </div>

                </div>
                <div className={styles.bottomWrapper}>
                    <div className={styles.bwLeftWrapper}>
                        <div className={styles.bwEachNav}>
                            <Link to={"/terms-of-use"}>Terms of Use</Link>
                        </div>
                        <div className={styles.bwEachNav}>
                            <Link to={"/privacy"}>Privacy Policy</Link>
                        </div>
                        {/* <div className={styles.bwEachNav}>
                            <Link to={"/"}>Security</Link>
                        </div> */}
                        <div className={styles.bwEachNav}>
                            <Link to={"/"}>Sitemap</Link>
                        </div>
                    </div>
                    <div className={styles.bwRightWrapper}>
                        <div>
                            <a href="https://facebook.com/">
                            </a>
                        </div>
                    </div>

                </div>
                <div className={styles.copyrightWrapper}>
                © 2024 Qnnox Services, All Rights Reserved
                </div>
            </div>
        </div>
    )
}

export default MainFooter