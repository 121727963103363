import React from 'react'
import { Link } from 'react-router-dom'
import styles from './VideoSection.module.css'

const VideoSection = () => {
    return (
        <div className={styles.videoWrapper}>
            <div className={styles.header}>
                <h2>The most powerful AI loyalty platform</h2>
                <h3>
                    Build the loyalty program of your dreams 20 times faster, and run it on the most powerful Engine with the best Workflow editor on the market. All powered by AI.
                </h3>
            </div>
            <div className={styles.videoContainer}>
                <video width="100%" muted autoPlay controls>
                    <source src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/qnnox-intro-video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className={styles.action}>
                <Link to={`/book-demo`} className={styles.bookDemoBtn}>Book A Demo</Link>
            </div>
        </div>
    )
}

export default VideoSection