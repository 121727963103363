import React, { useEffect } from 'react'
import styles from './ChannelLoyalty.module.css'
import MainFooter from '../Components/Footer/MainFooter'
import HowChannelLoyaltyWorks from '../Components/Pages/HowChannelLoyaltyWorks'
import LoyaltyProgramDashboard from '../Components/Pages/LoyaltyProgramDashboard'

const ChannelLoyalty = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div>
            <div className={styles.mainTitle}>
                <h1>
                    Channel Loyalty
                </h1>
            </div>
            <div className={styles.paragraphWrapper}>
                Channel Loyalty program anchors on unlocking the potential of sales channel partners to drive incremental growth for your brand.
                <p>
                    Create customized loyalty strategies to incentivize your dealers, distributors, agents, and channel partners.
                </p>
                <p>Together, you help your channel partners achieve their business objectives while driving top-line growth for your brand & delivering a meaningful ROI for all.
                </p>
            </div>
            <HowChannelLoyaltyWorks />
            <div className={styles.secTitle}>
                <h3>
                    Your Complete Solution for Incentives and Rewards
                </h3>
            </div>
            <LoyaltyProgramDashboard />
            <MainFooter />
        </div>
    )
}

export default ChannelLoyalty