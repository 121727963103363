import React from 'react';
import styles from './LogicBehindThis.module.css';
import { TbHandClick } from "react-icons/tb";
import { FaAward, FaPeoplePulling } from "react-icons/fa6";
import { FaGift } from "react-icons/fa";




const LogicBehindThis = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>The Logic Behind <br /> Employee Loyalty Programs</h2>
            <div className={styles.steps}>
                <div className={styles.step}>
                    <div className={`${styles.icon} ${styles.incentivizeIcon}`}>
                        <TbHandClick size={44} color="#B62CB9" />
                    </div>
                    <p className={styles.label}>Incentivize actions</p>
                </div>
                <div className={styles.halfArc}></div>
                <div className={styles.step}>
                    <div className={`${styles.icon} ${styles.badgeIcon}`}>
                        <FaAward size={44} color="#B62CB9" />
                    </div>
                    <p className={styles.label}>Assign badges</p>
                </div>
                <div className={styles.halfArcLower}></div>
                <div className={styles.step}>
                    <div className={`${styles.icon} ${styles.rewardIcon}`}>
                        <FaGift size={44} color="#B62CB9" />
                        
                    </div>
                    <p className={styles.label}>Award benefits</p>
                </div>
                <div className={styles.halfArc}></div>
                <div className={styles.step}>
                    <div className={`${styles.icon} ${styles.engagementIcon}`}>
                    <FaPeoplePulling size={44} color="#B62CB9" />
                    </div>
                    <p className={styles.label}>Encourage engagement</p>
                </div>
                <div className={styles.halfArcLower}></div>
                <div className={styles.step}>
                    <div className={`${styles.icon} ${styles.incentivizeIcon}`}>
                    <TbHandClick size={44}  />
                    </div>
                    <p className={`${styles.label} ${styles.light}`}>Incentivize actions</p>
                </div>
            </div>
        </div>
    );
};

export default LogicBehindThis;
