import React, { useEffect } from 'react'
import MainFooter from '../Components/Footer/MainFooter'
import styles from './CustomerSupport.module.css'

const CustomerSupport = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    

    return (
        <div>
            <div className={styles.firstPaint} >
                <div className={styles.mainHeader}>
                    <h1>Got an query?</h1>
                    <h2>Contact Us</h2>
                </div>
                <div className={styles.container}>
                    <div className={styles.officeSection}>
                        <div className={styles.officeHeader}>Head Office - Bangalore</div>
                        <div className={styles.officeAddress}>
                            Plot no. 9, Jayappa Layout, Near AECS Layout, Kudlu, Bommanahalli, Bangalore - 560068
                        </div>
                        <div className={styles.officeContact}>+91-9962255445</div>
                    </div>

                    <div className={styles.officeSection}>
                        <div className={styles.officeHeader}>Noida Office</div>
                        <div className={styles.officeAddress}>GT - 08, Sector - 117, Noida, 201307</div>
                        <div className={styles.officeContact}>+91-93184-93502</div>
                    </div>

                    <div className={styles.officeSection}>
                        <div className={styles.officeHeader}>Chennai Office</div>
                        <div className={styles.officeAddress}>
                            1st Floor, #111 Ashirwath Apartment, Puliyar 1st Main Road, Trustpuram, Kodambakkam - 600024
                        </div>
                        <div className={styles.officeContact}>+91-9962255445</div>
                    </div>

                    <div className={styles.officeSection}>
                        <div className={styles.officeHeader}>Hyderabad Office</div>
                        <div className={styles.officeAddress}>
                            G-3, Balaji Residency, RR Nagar, Old Bowenpally, Secunderabad - 500011
                        </div>
                        <div className={styles.officeContact}>+91-90595-77833</div>
                    </div>
                </div>

            </div>
            <MainFooter />
        </div>
    )
}

export default CustomerSupport