import React, { useEffect } from 'react';
import MainFooter from '../Components/Footer/MainFooter';
import styles from './CorporateGifting.module.css';

const CorporateGifting = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.headerSection}>
                    <h1>Corporate Gifts</h1>
                    <p className={styles.description}>
                        Corporate gifting is the act of sending an employee, client, or potential client a gift in an effort to create or strengthen the connection between the recipient and the company or brand. Some examples of physical corporate gifts include tech gadgets, gift cards, some forms of company-branded swag, and custom care packages. Non-physical corporate gifts include digital gift cards or an experience in lieu of a tangible item.
                    </p>
                </div>

                <div className={styles.imageWrapper}>
                    <div className={styles.eachImagePic}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/cat-animate.gif" />
                    </div>
                </div>


                <div className={styles.imageWrapper}>
                    <div className={styles.eachImagePicBig}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/cat-anm-2.gif" />
                    </div>
                </div>
                <div className={styles.imageWrapperMedium}>
                    <div className={styles.eachImagePicMedium}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/qnnox-assets/web/electronic.jpg" />
                    </div>
                </div>

                <div className={styles.headerSection}>
                    <h1>Corporate Gifting at Various Places</h1>
                    <p className={styles.description}>
                    </p>
                </div>



                <div className={styles.cardSection}>
                    <div className={styles.card}>
                        <h2>For Employees</h2>
                        <p className={styles.cardDescription}>
                            Corporate gifting for employees shouldn’t be a last-minute scramble, but rather an integral part of your employee care plan. Instead of assuming that all your employees feel recognized and appreciated by the same gifts, why not remove the guesswork and avoid wasting money on corporate gifts that fall flat by simply asking them? Turn it up a notch and dive into your employees’ RecognitionDNA to get a crystal clear picture of how to make the biggest impact on each individual with your corporate gifts. Try implementing an employee recognition program where top performers are rewarded throughout the year.
                        </p>

                    </div>

                    <div className={styles.card}>
                        <h2>For Sales Prospects</h2>
                        <p className={styles.cardDescription}>
                            Corporate gift-giving for sales prospects is a great way to raise brand awareness amongst potential clientele who may not be very familiar with your brand or company. A prospect may be choosing between multiple companies to do business with and the right gift received at the right time may be just what’s needed to motivate them.
                        </p>

                    </div>
                    <div className={styles.card}>
                        <h2>For Existing Clients</h2>
                        <p className={styles.cardDescription}>
                            Gifting to existing clients – even small appreciation gifts – shows clients that their business is highly valued and increases the likelihood of clients’ continued business. Clients who feel an emotional or personal connection with a company or brand tend to invest more and can even become brand ambassadors who extend referrals to their family and friends.
                        </p>

                    </div>
                    <div className={styles.card}>
                        <h2>Internal Conferences & Events</h2>
                        <p className={styles.cardDescription}>
                            Whether you’re hosting an internal conference or attending an external larger event, corporate gifting is a great way to engage with either your employees or event attendees. Arm your employees with everything they need to attend a work conference like a wireless charger, leather-bound notebook, and even some healthy snacks (because we all know that snacks are life in an all-day conference). Long after the event, they’ll have a tangible piece of the experience to take with them (minus the snacks, of course). Or at a larger industry event, corporate gifts are a great mechanism to help your brand stand out amongst all the others.
                        </p>

                    </div>
                </div>

                <div className={styles.benefitsSection}>
                    <h2>Benefits of Corporate Gifting</h2>
                    <ul className={styles.benefitsList}>
                        <li>Increases brand visibility and recognition.</li>
                        <li>Builds long-lasting relationships with clients and partners.</li>
                        <li>Enhances employee morale and loyalty.</li>
                        <li>Fosters a positive company culture.</li>
                        <li>Sets your brand apart from competitors.</li>
                    </ul>
                </div>

            </div>
            <MainFooter />
        </div>
    );
};

export default CorporateGifting;
