import React, { useEffect } from 'react'
import MainFooter from '../Components/Footer/MainFooter'
import styles from './CallCenterServices.module.css'

const CallCenterServices = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    

    return (
        <div>
            <div className={styles.mainHero}>
                <div className={styles.overlay}>
                    <div className={styles.header}>
                        <h1>AI-Ready Omni-Channel Call Center Solution</h1>
                        <p className={styles.subHeader}>Empowering Enterprises with Cutting-Edge Communication Tools</p>
                        <div className={styles.freeOffer}>
                            <span>Special Offer:</span> Enjoy a Free CRM with Your Subscription!
                        </div>
                    </div>
                    <div className={styles.textContainer}>
                        <p>
                            Customer care Service is an indispensable part of businesses across the globe.
                        </p>
                        <p>
                            Retaining your customers is as important as acquiring new ones in this competitive world of business.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.moreTxt}>
                <p>
                    They are constantly looking for call center providers to
                    manage ever-changing business needs and deliver optimum value to their customers.

                    Every missed call is equal to a missed opportunity.
                    To avoid that our Call Center Services provide 24/7 customer service support for your business, and the businesses can focus solely on their core competencies.
                    Our inbound and outbound call centre services are the perfect solutions for your outsourcing program.
                </p>
            </div>


            <div className={styles.container}>
                <div className={styles.features}>
                    <div className={styles.feature}>
                        <h3>Secure Interactions</h3>
                        <p>Our solution is PCI DSS-compliant, ensuring your customer data remains protected and secure.</p>
                    </div>
                    <div className={styles.feature}>
                        <h3>Seamless CRM Integration</h3>
                        <p>Effortlessly connect with industry-leading CRMs through pre-built connectors.</p>
                    </div>
                    <div className={styles.feature}>
                        <h3>Consistent Customer Experience</h3>
                        <p>Deliver a unified experience across multiple digital channels to enhance customer satisfaction.</p>
                    </div>
                    <div className={styles.feature}>
                        <h3>Flexible Deployment Options</h3>
                        <p>Choose from cloud, on-premise, or hybrid deployments to fit your enterprise's needs.</p>
                    </div>
                    <div className={styles.feature}>
                        <h3>Enterprise-Grade Outbound Dialer</h3>
                        <p>Boost your outreach with a high-performance outbound dialer, designed for scalability and efficiency.</p>
                    </div>
                </div>
            </div>

            <MainFooter />
        </div>
    )
}

export default CallCenterServices