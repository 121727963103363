import React, { useEffect, useState } from 'react';
import styles from './InfluencerLoyaltyHighlights.module.css';

const InfluencerLoyaltyHighlights = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5, // Trigger when 50% of the element is visible
        });

        const elements = document.querySelectorAll(`.${styles.animate}`);
        elements.forEach((element) => observer.observe(element));

        return () => {
            elements.forEach((element) => observer.unobserve(element));
        };
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <h3>Why Choose Us for Your Influencer Loyalty Program?</h3>
            </div>
            <div>
                <div className={styles.cardRow}>
                    <div
                        className={`${styles.smallCard} ${styles.violet} ${isVisible ? styles.animate : ''}`}
                    >
                        <span>Wide Range of Programs</span>
                    </div>
                    <div
                        className={`${styles.bigCard} ${isVisible ? styles.animate : ''}`}
                    >
                        <span>Partner with the Right Influencers</span>
                    </div>
                    <div
                        className={`${styles.smallCard} ${styles.orange} ${isVisible ? styles.animate : ''}`}
                    >
                        <span>Reward Them Suitably</span>
                    </div>
                </div>
                <div className={styles.cardRow}>
                    <div
                        className={`${styles.bigCard} ${isVisible ? styles.animate : ''}`}
                    >
                        <span>High-Quality Solutions</span>
                    </div>
                    <div
                        className={`${styles.smallCard} ${styles.red} ${isVisible ? styles.animate : ''}`}
                    >
                        <span>Best-in-Class Services</span>
                    </div>
                    <div
                        className={`${styles.bigCard} ${isVisible ? styles.animate : ''}`}
                    >
                        <span>Easy-to-Use Platform</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfluencerLoyaltyHighlights;
