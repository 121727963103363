import React from 'react';
import MainFooter from '../Components/Footer/MainFooter';
import styles from './LoyaltyRewardsFulfillment.module.css';

import LRFUSPs from '../Components/Pages/LRFUSPs';






const LoyaltyRewardsFulfillment = () => {

    
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.heroVideo}>
                <video
                    loop
                    muted
                    autoPlay
                    src="https://stylelink.s3.ap-south-1.amazonaws.com/stylist-profiles/warehouse-qnnox.mp4"
                />
            </div>
            <div className={styles.secondPaint}>
                <div className={styles.spContainer}>
                    <h1 className={styles.typingHeader}>Loyalty Rewards Fulfillment</h1>
                    <p className={styles.typingParagraph}>Our cutting-edge technology caters to the entire process from placing an order to tracking, not just till delivery but also handling replacements and after-service in the most efficient way.</p>
                </div>
            </div>

            <div className={styles.textWrapper}>
                <div className={styles.headerTitle}>
                    <div>Loyalty Rewards Fulfillment</div>
                </div>
                <div className={styles.contentWrapper}>
                    It is more than often that a company designs a Loyalty Program but the customer or employee is not able to reap its benefits due to logistics issues or proper paperwork. Our personalized dedication to loyalty reward fulfillment programs and redemption ensures that the rewards and incentives reach the consumers.
                    We provide customized end-to-end loyalty rewards fulfilment programs and services with reward sourcing, distribution and logistics to the loyalty members across India. Build your business with a dynamic rewards experience via our loyalty reward fulfillment services and create a lasting impression.
                    We focus on providing excellent services to fulfill the desires of your members quickly with high-quality rewards.
                </div>
            </div>


            <LRFUSPs />


            <MainFooter />
        </div>
    );
}

export default LoyaltyRewardsFulfillment;
