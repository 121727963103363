import React from 'react';
import styles from './InfluencerLoyaltyConnecting.module.css';

const InfluencerLoyaltyConnecting = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.largeCard}>
                {/* Large Card with Image */}
                <img src="https://grin.co/wp-content/uploads/2022/01/IM_Beauty_Influencer_Collaborations_2022-Q4-10_Featured2.webp" alt="Worker 1" className={styles.cardImage} />
            </div>
            <div className={styles.smallCard} style={{ backgroundColor: '#756EB2' }}>
                {/* Icon or Symbol */}
                <i className="fas fa-gift"></i>
            </div>
            <div className={styles.largeCard}>
                <img src="https://carbonlawpartners.com/app/uploads/2024/07/Image-for-Kate-Article-scaled.jpg" alt="Worker 2" className={styles.cardImage} />
            </div>
            <div className={styles.smallCard} style={{ backgroundColor: '#F4913F' }}>
                <i className="fas fa-shopping-cart"></i>
            </div>
            <div className={styles.largeCard}>
                <img src="https://images.dmnews.com/wp-content/uploads/2024/06/Untitled-design-3-380x250.jpg" alt="Worker 3" className={styles.cardImage} />
            </div>
            <div className={styles.smallCard} style={{ backgroundColor: '#4CAF50' }}>
                <i className="fas fa-star"></i>
            </div>
        </div>
    );
};

export default InfluencerLoyaltyConnecting;
