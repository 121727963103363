import React, { useEffect } from 'react';
import MainFooter from '../Components/Footer/MainFooter';
import styles from './AboutUs.module.css';

const AboutUs = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.firstPaintWrapper}>

                <div className={styles.headingWrapper}>
                    <h1 className={styles.mainTitle}>About Us</h1>
                    <p className={styles.introText}>
                        <div className={styles.boldNWhite}>Qnnox</div> is a Next Gen Technology company powered by Caliber Inno Enterprises Pvt Ltd. Companies are moving away from the typical marketing model and heavily investing in their stakeholders through various rewards and loyalty programs. In 2014, experts from the industry put their minds together and decided to create an organization whose core objective is to help business houses consolidate their association with their customers, partners, and employees.
                    </p>

                    <h2 className={styles.subTitle}>Redefining Relationship Programs</h2>
                    <p className={styles.contentText}>
                        Every organization spends huge amounts on their stakeholders. Despite spending, attrition cannot be curbed. The introduction of structured reward programs can capture the imagination of the target audience and promote positive behavior. Today, organizations reward performance and achievements on an ad-hoc basis, leading to dissatisfaction. The intervention of an expert like Qnnox helps create tailor-made reward programs to motivate and reward your stakeholders. Qnnox helps bring structure to the otherwise random ceremony, streamlines budgets, and presents a very attractive interface for the entire rewarding activity.
                    </p>
                </div>
            </div>


            <div className={styles.contentWrapper}>
                <div className={styles.managementHeader}>
                    <h3>
                        Leadership Team
                    </h3>
                </div>

                <div className={styles.managementWrapper}>
                    <div className={styles.managementCard}>
                        <h3 className={styles.name}>Surendra Kumar</h3>
                        <p className={styles.role}>CEO & Founder</p>
                        <p className={styles.bio}>
                            Surendra has over 16 years of experience within the loyalty industry, including key roles at Cross Pen, eYantra, and Morovo. He develops and drives marketing initiatives for clients, including member lifecycle management, promotional campaigns, digital marketing, social media strategy, market research, and investor relations.
                        </p>
                    </div>

                    <div className={styles.managementCard}>
                        <h3 className={styles.name}>Navneet Chandan</h3>
                        <p className={styles.role}>CTO & Co-Founder</p>
                        <p className={styles.bio}>
                            Navneet, with a background in technology and a passion for innovation, co-founded Qnnox to bring cutting-edge tech solutions to the loyalty industry. With extensive experience in both startup environments and enterprise-level tech, Navneet drives the technical vision and development at Qnnox.
                        </p>
                    </div>
                </div>
            </div>
            <MainFooter />
        </div>
    );
};

export default AboutUs;
