import React, { useEffect } from 'react'
import MainFooter from '../Components/Footer/MainFooter'
import MainHeader from '../Components/Header/MainHeader'
import styles from './Privacy.module.css'

const Privacy = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    

    return (
        <div>
            <div className={styles.bodyWrapper}>


                <h1>
                    Qnnox Privacy Policy
                </h1>
                <h4>
                    This Privacy Policy outlines the practices of qnnox.com ("qnnox", "we", "us", or "our") regarding the collection, use, and disclosure of your personal information when you use our website (the "Website") and our services (the "Services").
                </h4>
                <ul>
                    <li>
                        Information We Collect
                    </li>
                    <p>
                        We collect several different types of information from and about users of our Website and Services.

                        Personal Information: We collect personal information that you provide to us, such as your name, email address, phone number, and address.
                        Usage Data: We collect usage data about your activity on our Website and Services, such as the pages you visit, the time you spend on each page, and the links you click.
                        Device Information: We collect information about the device you use to access our Website and Services, such as your IP address, browser type, operating system, and device identifiers.
                    </p>
                    <li>
                        How We Use Your Information
                    </li>
                    <p>
                        We use the information we collect to:

                        Provide, operate, and maintain our Website and Services;
                        Improve, personalize, and expand our Website and Services;
                        Send you marketing communications, such as newsletters and promotional offers;
                        Respond to your inquiries and requests;
                        Prevent fraud and protect our users.
                    </p>
                    <li>
                        Sharing Your Information
                    </li>
                    <p>
                        We share your information with third-party service providers who help us provide and improve our Website and Services. These service providers are bound by contractual obligations to keep your information confidential and to use it only for the purposes for which we disclose it.

                        We may also disclose your information if we are required to do so by law or in the good faith belief that such action is necessary to comply with legal requirements, protect our rights or property, or prevent harm to ourselves or others.
                    </p>
                    <li>
                        Your Choices
                    </li>
                    <p>
                        You have choices about the information you provide to us and how we use it. You can:

                        Access and update your personal information.
                        Opt out of receiving marketing communications from us.
                        Request that we delete your personal information.
                    </p>
                    <li>
                        Data Retention
                    </li>
                    <p>
                        We will retain your personal information for as long as your account is active or as needed to provide you with the Services. We will also retain your personal information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
                    </p>
                    <li>
                        Security
                    </li>
                    <p>
                        We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your information.
                    </p>
                    <li>
                        Children's Privacy
                    </li>
                    <p>
                        Our Website and Services are not directed to children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we discover that we have collected personal information from a child under the age of 13, we will immediately delete that information.
                    </p>
                    <li>
                        Changes to This Privacy Policy
                    </li>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Website. You are advised to review this Privacy Policy periodically for any changes.
                    </p>
                    <li>
                        Contact Us
                    </li>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us:
                        By email: privacy@qnnox.com
                    </p>
                </ul>
            </div>
            <MainFooter />
        </div>
    )
}

export default Privacy